// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
export const FACEBOOK_AUTH_FAIL = 'FACEBOOK_AUTH_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const LOGOUT = 'LOGOUT';


// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';


//Sightings


export const GET_SIGHTING_ITEMS_SUCCESS =  'GET_SIGHTING_ITEMS_SUCCESS';
export const GET_SIGHTING_ITEMS_FAIL = 'GET_SIGHTING_ITEMS_FAIL';
export const ADD_SIGHTING_ITEM_SUCCESS = 'ADD_SIGHTING_ITEM_SUCCESS'
export const ADD_SIGHTING_ITEM_FAIL = 'ADD_SIGHTING_ITEM_FAIL'
export const CREATE_SIGHTING_ITEM_SUCCESS = 'CREATE_SIGHTING_ITEM_SUCCESS'
export const CREATE_SIGHTING_ITEM_FAIL = 'CREATE_SIGHTING_ITEM_FAIL'
export const REMOVE_SIGHTING_ITEM_SUCCESS = 'REMOVE_SIGHTING_ITEM_SUCCESS'
export const REMOVE_SIGHTING_ITEM_FAIL = 'REMOVE_SIGHTING_ITEM_FAIL'
export const CLEAR_SIGHTING = 'CLEAR_SIGHTING'
export const UPDATE_SIGHTINGS_ITEMS_SUCCESS = 'UPDATE_SIGHTINGS_ITEMS_SUCCESS'
export const LOAD_SIGHTING_ITEMS_SUCCESS = 'LOAD_SIGHTINGS_ITEMS_SUCCESS'
export const LOAD_SIGHTING_ITEMS_FAIL = 'LOAD_SIGHTINGS_ITEMS_FAIL'
export const LOAD_USER_SIGHTING_ITEMS_SUCCESS = 'LOAD_USER_SIGHTINGS_ITEMS_SUCCESS'
export const LOAD_USER_SIGHTING_ITEMS_FAIL = 'LOAD_USER_SIGHTINGS_ITEMS_FAIL'
export const GET_USER_SIGHTING_ITEMS_SUCCESS =  'GET_USER_SIGHTING_ITEMS_SUCCESS';
export const SET_SIGHTING_LOADING =  'SET_SIGHTING_LOADING';
export const REMOVE_SIGHTING_LOADING =  'REMOVE_SIGHTING_LOADING';
export const REMOVE_ITEM_CREATED_SUCCESS =  'REMOVE_ITEM_CREATED_SUCCESS';


export const GET_SIGHTING_DETAIL_SUCCESS = 'GET_SIGHTING_DETAIL_SUCCESS'
export const GET_SIGHTING_DETAIL_FAIL = 'GET_SIGHTING_DETAIL_FAIL'